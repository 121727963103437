@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}
body {
  background: linear-gradient(
    30deg,
    rgba(179, 255, 209, 1) 42%,
    rgba(0, 212, 255, 1) 100%
  );
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  display: block !important;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-inner-card {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.small-text,
.small-text a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.small-text a {
  color: #167bff;
}

.margin-10 {
  margin-top: 10px;
}

.nav-link-block {
  border-right: "1px solid grey";
}

.nav-link-text {
  color: #7f7d7d !important;
  margin: 0;
}

.nav-link-text:hover {
  color: #0dcaf0 !important;
  margin: 0;
  transform: scale(1.5);
}

.blur {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
}

.straight {
  word-break: keep-all;
}

/* Search Box */
:root {
  --primary-color: #fff;
  --accent-color: #f50057;

  --text-color: #263238;
  --body-color: #80deea;
  --main-font: "roboto";
  --font-bold: 700;
  --font-regular: 400;
}

* {
  box-sizing: border-box;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}

input,
button {
  border: none;
  background: none;
  outline: 0;
}
button {
  cursor: pointer;
}
.SearchBox-input::placeholder {
  /* No es un seudoelemento estandar */
  color: #000000;
  opacity: 0.6;
}
/* Chrome, Opera ySafari */
.SearchBox-input::-webkit-input-placeholder {
  color: #000000;
}
/* Firefox 19+ */
.SearchBox-input::-moz-placeholder {
  color: #000000;
}
/* IE 10+ y Edge */
.SearchBox-input:-ms-input-placeholder {
  color: #000000;
}
/* Firefox 18- */
#formGroupExampleInput:-moz-placeholder {
  color: #000000;
}

.SearchBox {
  --height: 2.8em;
  display: flex;
  border-radius: var(--height);
  background-color: var(--primary-color);
  height: var(--height);
}
.SearchBox:hover .SearchBox-input {
  font-size: 1.2em;
}

.SearchBox-input {
  padding-left: 2em;
  padding-right: 1em;
  width: 500px;
  font-size: 15px;
  color: #263238;
  transition: 0.45s;
}
.SearchBox-button {
  display: flex;
  border-radius: 50%;
  height: var(--height);
  background-color: var(--accent-color);
  transition: 0.3s;
  padding: 9px;
}

.SearchBox-pin {
  display: flex;
  border-radius: 50%;
  width: var(--height);
  height: var(--height);
  background-color: var(--accent-color);
  padding: 5px 5px 10px 10px;
}
.SearchBox-button:active {
  transform: scale(0.85);
}
.SearchBox-icon {
  margin: auto;
  color: #fff;
}

@media screen and (min-width: 400px) {
  .SearchBox:hover .SearchBox-input {
    width: 700px;
  }
}

.card-data:hover {
  transform: scale(1.1);
  cursor: pointer;
  color: #00bcd4;
}

.span-content {
  border: "1px solid grey";
  margin-left: "10px";
  padding: "5px";
  border-radius: "25px";
}

.light-content {
  background: #fff !important;
  color: black !important;
}

.light-content:hover {
  background-color: #525252 !important;
  color: #ffffff !important;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/* Card on Explore pgae */
.badge-custom span {
  background-color: #fffbec;
  width: 25px;
  height: 25px;
  padding-bottom: 3px;
  border-radius: 5px;
  display: flex;
  color: #fed85d;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 50px;
  height: 50px;
  background-color: #eee;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  text-transform: uppercase;
}

/* .badge-custom-2:hover{
  filter: blur(2px);
} */

.call-directions {
  /* display: none; */
}

.card-block:hover > .call-directions {
  display: block;
}

.btn-pills:hover {
  background-color: #000000;
}

.btn-pills:hover .btn-pills-text {
  color: #fff;
}

.card-fancy {
  transition: transform 250ms;
}

.card-fancy:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.form-control .special-input {
  background-color: #fff;
}

/* sidebar  */

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 400px;
  max-width: 400px;
  transition: all 0.3s;
}

#sidebar.active {
  margin-left: -400px;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #7386d5;
  background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    min-width: 250px;
    max-width: 250px;
    transition: all 0.3s;
  }
  
  #sidebar.active {
    margin-left: -250px;
  }
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
 
/* spinners */

.spin-div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#save {
  animation-name: cssAnimation;
  animation-duration:3s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

#save:active {
  transform: scale(0.9);  
}

/* loader */
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  color: #000000;
}

@media only screen and (max-width: 768px) {

 .bottom-tab {
   font-size: 12px;
 }
}

/* back to top */

#btn-back-to-top {
  position: absolute; /* Fixed/sticky position */
  bottom: 100px; /* Place the button at the bottom of the page */
  left: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background: rgba(0,0,0,0.8);
  backdrop-filter:blur(10px);
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
}

#btn-back-to-top:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}

